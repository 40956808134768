<script setup lang="ts">
import { Card } from "@/components/Ui";

const { t } = useI18n();
</script>
<template>
  <Card :class="`${$style[`root`]}`">
    <div :class="`${$style[`icon`]}`">
      <nuxt-img
        src="/img/block-visa-platinum.png"
        width="315"
        height="170"
        sizes="sm:100vw md:315px"
        aria-label="visa-platinum"
        loading="lazy"
        placeholder
      />
    </div>
    <div :class="`${$style[`title`]} ${$style[`title-${$i18n.locale}`]}`">
      Platinum<br />
      credit
    </div>
    <div :class="`${$style[`desc`]}`">
      {{ t("block.privatePlatinumCredit.desc") }}
    </div>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col justify-start gap-[30px];
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[50px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-neutral-100;
}

.icon {
  @apply mb-auto ml-auto;

  & img {
    @apply w-[200px] lg:w-[315px];
  }
}

.title {
  @apply text-neutral-800 font-bold mt-auto;
  @apply leading-none;
  @apply text-[40px] xxs:text-[44px] md:text-[42px] lg:text-[58px];
  &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
  &-be,
  &-bg,
  &-de,
  &-it,
  &-pt,
  &-tr,
  &-ua,
  &-zh,
  &-es,
  &-fr,
  &-ja,
  &-ru,
  &-th,
  &-hi,
  &-ar,
  &-bn,
  &-ko,
  &-vi,
  &-sw{
    @apply text-[40px] xxs:text-[44px] md:text-[42px] lg:text-[58px];
  }
  &-pl {
    @apply text-[28px] xxs:text-[36px] xs:text-[42px] md:text-[38px] lg:text-[50px] xl:text-[58px];
  }
}

.desc {
  @apply text-neutral-900 text-[14px] xxs:text-[18px] xs:text-[20px] lg:text-[24px] leading-snug;
}
</style>
