<script setup lang="ts">
import { Card } from "@/components/Ui";

const localePath = useLocalePath();
const { t } = useI18n();
</script>
<template>
  <Card
    :wrapper-class="$style[`root-wrapper`]"
    :action-class="$style[`root-action`]"
    :title-class="`${$style[`root-title`]} ${
      $style[`root-title-${$i18n.locale}`]
    }`"
    :class="`${$style[`root`]}`"
  >
    <template #title>
      <span v-html="$t('block.businessModel.title')" />
    </template>
    <ul
      :class="`${$style[`root-list`]} ${$style[`root-list-${$i18n.locale}`]}`"
    >
      <li
        v-for="index in 3"
        :key="index"
      >
        <span>0%</span> {{ t(`block.businessModel.li${index}`) }}
      </li>
    </ul>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col gap-8
  px-[30px] py-[50px] lg:px-[50px] lg:py-[70px]
  lg:min-h-[820px] bg-white;
  box-shadow: 0px 275px 110px rgba(0, 0, 0, 0.01),
    0px 155px 93px rgba(0, 0, 0, 0.05), 0px 69px 69px rgba(0, 0, 0, 0.09),
    0px 17px 38px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);

  &-title {
    @apply font-black leading-none pb-1;
    background: linear-gradient(
      94.04deg,
      #313438 14.07%,
      rgba(49, 52, 56, 0.28) 100.15%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    @apply text-[38px] xxs:text-[46px] xs:text-[54px] md:text-[88px] lg:text-[114px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-be,
    &-bg,
    &-tr,
    &-ua,
    &-zh,
    &-ru,
    &-th,
    &-hi,
    &-ar,
    &-ko,
    &-bn {
      @apply text-[38px] xxs:text-[46px] xs:text-[54px] md:text-[88px] lg:text-[114px];
    }
    &-th {
      @apply leading-[1.4];
    }
    &-bn {
      @apply leading-[1.24];
    }
    &-zh {
      @apply leading-[1.2];
    }
    &-ua {
      @apply text-[30px] xxs:text-[38px] xs:text-[46px] md:text-[88px] lg:text-[114px];
    }
    &-ja {
      @apply text-[32px] xxs:text-[40px] xs:text-[48px] md:text-[88px] lg:text-[114px];
    }
    &-pt {
      @apply text-[34px] xxs:text-[42px] xs:text-[50px] md:text-[72px] lg:text-[96px] xl:text-[114px];
    }
    &-vi,
    &-sw,
    &-pl,
    &-it,
    &-fr {
      @apply text-[36px] xxs:text-[42px] xs:text-[48px] md:text-[68px] lg:text-[90px]  xl:text-[102px];
    }
    &-es {
      @apply text-[36px] xxs:text-[42px] xs:text-[48px] md:text-[74px] lg:text-[96px]  xl:text-[110px];
    }
    &-de {
      @apply text-[28px] xxs:text-[34px] xs:text-[40px] md:text-[82px] lg:text-[110px];
    }
  }

  &-list {
    @apply mt-auto flex flex-col gap-[18px] md:gap-[28px] lg:gap-[30px] text-neutral-800;

    & > li {
      @apply flex gap-2 md:gap-3 lg:gap-4;
    }
    &-be,
    &-bg,
    &-de,
    &-it,
    &-tr,
    &-zh,
    &-es,
    &-ru,
    &-hi,
    &-ar,
    &-ko {
      @apply leading-[1.1] md:leading-[90%] text-[20px] xxs:text-[26px] xs:text-[30px] md:text-[44px] lg:text-[60px];
    }
    &-vi {
      @apply leading-[1.1] md:leading-[90%] text-[18px] xxs:text-[24px] md:text-[26px] lg:text-[36px] xl:text-[42px];
    }
    &-th {
      @apply leading-[1.1] md:leading-[90%] text-[18px] xxs:text-[24px] xs:text-[28px] md:text-[30px] lg:text-[38px] xl:text-[46px];
    }
    &-sw {
      @apply leading-[1.1] md:leading-[90%] text-[18px] xxs:text-[24px] xs:text-[28px] md:text-[32px] lg:text-[42px] xl:text-[48px];
    }
    &-en {
      @apply leading-[1.1] md:leading-[90%] text-[18px] xxs:text-[24px] xs:text-[28px] md:text-[32px] lg:text-[44px] xl:text-[50px];
    }
    &-pt {
      @apply leading-[1.1] md:leading-[90%] text-[20px] xxs:text-[26px] xs:text-[28px] md:text-[40px] lg:text-[52px] xl:text-[60px];
    }
    &-fr {
      @apply leading-[1.1] md:leading-[90%] text-[20px] xxs:text-[26px] xs:text-[30px] md:text-[40px] lg:text-[56px];
    }
    &-de {
      @apply leading-[1.1] md:leading-[90%] text-[20px] xxs:text-[24px] xs:text-[28px] md:text-[44px] lg:text-[60px];
    }
    &-pl {
      @apply leading-[1.1] md:leading-[90%] text-[18px] xxs:text-[22px] xs:text-[28px] md:text-[36px] lg:text-[48px] xl:text-[56px];
    }
    &-bn,
    &-ua,
    &-ja {
      @apply leading-[1.1] md:leading-[90%] text-[18px] xxs:text-[22px] xs:text-[28px] md:text-[42px] lg:text-[56px] xl:text-[60px];
    }
  }
}
</style>
